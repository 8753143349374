/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";
// ... or 
@import "~@ng-select/ng-select/themes/material.theme.css";
 $primary-color:#da1773;
 $primary-color-progress:#f9a000;
 $primary-color-recheck:#e85a5a;
 $primary-color-complete:#5ca2e8;
 $primary-color-booked:#5ca2e8;
 $white-color:#fff;
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap);
@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../fonts/inter-light.woff2) format("woff2"), url(../fonts/inter-light.woff) format("woff")
}
;
@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/inter-regular.woff2) format("woff2"), url(../fonts/inter-regular.woff) format("woff")
}
;
@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../fonts/inter-medium.woff2) format("woff2"), url(../fonts/inter-medium.woff) format("woff")
}
;
@font-face {
	font-family: Inter;
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../fonts/inter-bold.woff2) format("woff2"), url(../fonts/inter-bold.woff2) format("woff")
}
;
 .authentication-bg {
	background-image: url(../images/authentication-bg.jpg);
}
html {
	scroll-behavior: smooth;
}
// body {
//     background: #FFF7F3;
// }
body[data-sidebar=dark] .vertical-menu,
body[data-sidebar=dark] .navbar-brand-box,
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
	background:#354d9b !important;
}
body[data-sidebar=dark] #sidebar-menu ul li a i,
body[data-sidebar=dark] #sidebar-menu ul li a,
body[data-sidebar=dark] .menu-title {
	color: #fff;
	opacity: 1; 
}
.metismenu li.active>a, .metismenu li:hover>a {
    background: #da1773;
}
.apexcharts-toolbar {
    display: none !important;
}
.uptodown-icon {
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 6px center/8px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px;
}
/*****  start Alert Popup  *****/

.ion-overlay-wrapper {
	border-radius: 13px;
	overflow: hidden;
	display: flex;
	background: #f9f9f9;
	-ms-flex-direction: column;
	flex-direction: column;
	position: absolute;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 250px;
	display: inline-block;
	width: 250px;
	font-family: Poppins, sans-serif;
}
.sc-ion-alert-ios-s {
	height: 100%;
	width: 100%;
}
.alert-head.sc-ion-alert-ios {
	padding-top: 12px;
	padding-bottom: 7px;
	text-align: center;
	padding-inline-start: 16px;
	padding-inline-end: 16px;
}
.alert-head.sc-ion-alert-ios h2 {
	margin-top: 8px;
	color: #000;
	font-size: 17px;
	font-weight: 600;
	line-height: 20px;
	padding: 0px;
	margin-bottom: 0px;
}
.alert-message.sc-ion-alert-ios {
	-webkit-padding-start: 16px;
	padding-inline-start: 16px;
	-webkit-padding-end: 16px;
	padding-inline-end: 16px;
	padding-top: 0;
	padding-bottom: 21px;
	color: #000;
	font-size: 12px;
	text-align: center;
	font-family: Poppins, sans-serif;
}
button.alert-button {
	outline: none;
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
	border-radius: 0;
	min-width: 50%;
	height: 44px;
	border-top: 0.55px solid #0000003b;
	border-right: 0.55px solid #0000003b;
	background-color: transparent;
	color: $primary-color;
	font-size: 17px;
	/* overflow: hidden; */
	border-bottom: 0px !important;
	border-left: 0;
	font-weight: bold;
}
.alert-button-group.sc-ion-alert-ios {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	width: 100%;
	margin-right: unset;
	-webkit-margin-end: -0.55px;
	margin-inline-end: -0.55px;
	margin-right: -0.55px;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
button.alert-button+button.alert-button {
	border-right: 0px !important;
	border-left: 0px !important;
}
.sc-ion-alert-ios-s .sc-ion-alert-ios-backdrop {
	background: #00000054;
	position: absolute;
	left: 0px;
	top: 0px;
	display: block;
	height: 100%;
	width: 100%;
	z-index: 999o;
}
.alert_popup .modal-content {
	background: none;
	border: 0px;
}
/*****  End Alert Popup  *****/

/*****  start Sidebar menu *****/

#sidebar-menu ul li a {
	text-transform: capitalize;
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
	background: #293041;
	color: #d7e4ec;
}
.metismenu li.active a *,
.metismenu li:hover a *,
#sidebar-menu ul li ul.sub-menu li.active a {
	color: $white-color !important;
}
.navbar-brand-box {
	text-align: center;
}
@media (max-width: 992px) {
	.sidebar-enable .vertical-menu {
		display: block;
		width: 240px !important;
		left: 0px !important;
		transition: all 0.3s;
	}
	.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
		display: contents;
		padding-left: 0px;
	}
	div#layout-wrapper .vertical-menu {
		display: block;
		left: -300px;
		transition: all 0.3s;
		z-index: 999;
		position: fixed;
	}
	body #layout-wrapper .navbar-brand-box {
		width: 73px !important;
	}
	.navbar-brand-box {
		display: block !important;
	}
	.vertical-collpsed .footer {
		left: 0px !important;
	}
	.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
		background: transparent !important;
		width: auto !important;
	}
}
/*****  End Sidebar menu  *****/

/***** pagination-template  *****/

pagination-template ul.ngx-pagination li {
	width: 34px;
	cursor: pointer !important;
	height: 34px;
	padding: 0px !important;
	text-align: center;
	line-height: 34px;
	border-radius: 60px !important;
	margin: 0 3px;
}
pagination-template ul.ngx-pagination li.pagination-next,
pagination-template ul li.pagination-previous span,
pagination-template ul li.pagination-next a,
pagination-template ul li.pagination-previous span,
pagination-template ul.ngx-pagination li.pagination-previous a {
	font-size: 0px !important;
}
.ngx-pagination .pagination-previous a:before,
.ngx-pagination .pagination-previous.disabled:before {
	content: "\F0141" !important;
	font-family: "Material Design Icons" !important;
}
pagination-template ul li.pagination-next a:after,
pagination-template ul.ngx-pagination li.pagination-previous a:before {
	font-size: .9rem;
}
pagination-template ul.ngx-pagination {
	margin: 2px 0;
	white-space: nowrap;
	justify-content: flex-end;
	padding: 0px;
	display: flex;
}
pagination-template ul.ngx-pagination li a {
	padding: 0px !important;
	display: inline-block;
	line-height: 25px;
}
pagination-template ul.ngx-pagination li a:after {
	line-height: 34px;
	margin: 0px !important;
}
pagination-template ul.ngx-pagination li a .show-for-sr {
	display: none;
}
pagination-template ul.ngx-pagination li.current {
	background-color: $primary-color;
	cursor: default !important;
}
pagination-template ul.ngx-pagination li:hover {
	background-color: $primary-color;
}
pagination-template ul.ngx-pagination li:hover a {
	background: no-repeat;
	color: $white-color;
}
.ngx-pagination .pagination-previous a::before,
.ngx-pagination .pagination-previous.disabled::before {
	margin: 0px !important;
}
.ngx-pagination .pagination-next a:after,
.ngx-pagination .pagination-next.disabled:after {
	content: "\F0142" !important;
	font-family: "Material Design Icons" !important;
	font-size: 0.9rem;
}
/*****  End pagination  *****/

.auth-form-group-custom .form-control {
	padding-right: 60px;
}
.auth-form-group-custom .auti-custom-eye {
	left: auto;
	right: 19px;
	cursor: pointer;
}
.full-button {
	min-width: 100% !important;
}
body {
	overflow-x: hidden;
}
.sidebar-enable .navbar-brand-box {
	padding: 0 10px !important;
}
.copyright a {
	color: #505d69;
}
.password_box {
	position: relative;
}
.password_box .auti-custom-eye {
	left: auto;
	right: 12px;
	cursor: pointer;
	color: $primary-color !important;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 20px;
}
/***** Colors *****/

.custom-control-input:checked~.custom-control-label::before,
.page-item.active .page-link {
	border-color: $primary-color;
	background-color: $primary-color;
}
.btn-primary {
	background: $primary-color !important;
	border-color: $primary-color !important;
}
.text-primary,
body a.text-primary:focus,
body a.text-primary:hover,
.text-solitud:hover {
	color: $primary-color !important;
}
.auth-form-group-custom .auti-custom-input-icon {
	color: $primary-color !important;
}
.btn-warning,
.custom-accordion-arrow .card-header .accor-arrow-icon {
	box-shadow: none !important;
	background-color: $primary-color !important;
	border-color: $primary-color !important;
}
/***** apexcharts *****/

.btn-primary.focus,
.btn-primary:focus {
	box-shadow: none !important;
}
.dropdown-item:not([href]),
a:not([href]) {
	cursor: pointer;
	color: #212529;
}
.custom-file-input {
    cursor: pointer;
}
.float-right .custom-select {
	z-index: +99;
	position: relative;
	cursor: pointer;
}
.apexcharts-legend.position-bottom.apexcharts-align-center {
	margin-top: 10px !important;
}
.apx_chart {
	float: left;
	width: 100%;
}
.btn-w {
	padding: 5px 20px;
	margin-left: 15px;
}
.btn_style {
	background: none;
	border: 0px;
	padding: 0px;
}
.text-solitud {
	color: #808080;
}
.page-title-right2 {
	display: flex;
	align-items: center;
}
.page-title-box .breadcrumb * {
	font-weight: 500 !important;
	font-family: Inter, sans-serif;
	color: #000000b3;
    font-size: 16px;
	cursor: pointer;
}
.description-text {
	white-space: initial;
	font-size: 12px;
	max-width: 300px;
}
.img-circle {
	border-radius: 50%;
}
.profile-details {
	text-align: center;
}
.user-img {
	margin-bottom: 10px;
}
.select2-container {
	width: 100% !important;
}
.popup-w {
	max-width: 700px;
}
.note-editable.card-block {
	max-height: 200px !important;
}
.dataTables_length label {
	font-weight: normal;
	text-align: left;
	white-space: nowrap;
	display: inline-block;
	margin-bottom: .5rem;
	font-size: .9rem !important;
	line-height: 1.5;
	color: #505d69;
}
.dataTables_length label .custom-select {
	width: auto;
	display: inline-block;
}
.dataTables_filter {
	text-align: right;
}
.dataTables_filter label {
	font-weight: normal;
	white-space: nowrap;
	text-align: left;
}
.dataTables_filter label .form-control {
	margin-left: 0.5em;
	display: inline-block;
	width: auto;
}
.table-responsive>.row {
	margin: 0px;
}
.table-responsive>.row>div[class^="col-"]:first-child {
	padding-left: 0;
}
.table-responsive>.row>div[class^="col-"]:last-child {
	padding-right: 0px;
}
.popup-inner .card-title {
	font-weight: 500;
	text-transform: initial;
}
.table-responsive .table.mb-0 {
	margin-bottom: 15px !important;
}
.table-responsive .table.mb-0 {
	margin-bottom: 15px !important;
}
.table-responsive .dataTables_info {
	padding-top: 0.85em;
	white-space: nowrap;
}
.table-responsive .paging_simple_numbers {
	margin: 0;
	white-space: nowrap;
	text-align: right;
}
.table-responsive .paging_simple_numbers .pagination {
	margin: 2px 0;
	white-space: nowrap;
	justify-content: flex-end;
}
.table_page .card-body .table th,
.table_page .card-body .table td {
	font-size: 12px;
	padding: 10px 5px;
	// list-style: 15px;

}
 
.btn_style i {
	display: block;
}
.page-link:hover {
	color: $primary-color;
}
.filter_boxs {
	display: flex;
	justify-content: flex-end;
}
.dataTables_filter_select {
	margin-right: 15px;
	margin-bottom: .5rem;
}
.dataTables_filter_select .filter_select {
	display: inline-block;
	width: auto;
	border: 1px solid #ced4da;
	outline: none;
}
.button_detail {
	text-align: end;
}
.bg_img {
	height: 35px;
	width: 35px;
	border-radius: 60px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.user_img {
	display: inline-block;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 50%;
	padding-bottom: 50%;
	border-radius: 100%;
}
.btn_style,
.btn_style+.text-danger i:before,
.btn_style,
.btn_style+.text-danger i,
.btn_style {
	line-height: 18px;
}
/*****  start Switch *****/

.switch {
	position: relative;
	display: inline-block;
	width: 36px;
	height: 20px;
	margin: 0px;
}
.switch input {
	display: none;
}
.switch .slider.round {
	border-radius: 26px;
}
.switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}
.switch input:checked+.slider:before {
	-webkit-transform: translateX(14px);
	-ms-transform: translateX(14px);
	transform: translateX(14px);
}
.switch .slider.round:before {
	border-radius: 50%;
}
.switch .slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 4px;
	bottom: 3px;
	background-color: $white-color;
	-webkit-transition: .4s;
	transition: .4s;
}
.switch input:checked+.slider {
	background-color: #28a745;
}
.switch .checkbox label::after {
	width: auto;
}
/***** End Switch *****/

/***** Ng Select *****/

.multiple-select .form-group {
	padding: 0px !important;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-clear-wrapper,
.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper {
	border-top: 0px;
}
.multiple-select .ng-select .ng-select-container {
	border-color: #ced4da !important;
}
.multiple-select .ng-select .ng-select-container:after {
	display: none;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
	border-top: 0px !important;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	background-color: $primary-color;
	padding: 0px;
	margin: 0 3px 3px 0;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value {
	align-items: baseline;
}
.multiple-select .ng-select .ng-select-container {
	border-color: #ced4da !important;
	min-height: 34px !important;
}
.multiple-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	line-height: 20px;
	min-height: 20px;
	padding: 5px 10px;
}
.multiple-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
	color: $primary-color;
}
.multiple-select .ng-dropdown-panel.ng-select-bottom {
	top: 101%;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
	font-size: 10px;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
	padding-top: 5px !important;
}
.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
	color: $primary-color;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
	border-right: 1px solid #ffffff;
	background: no-repeat;
	color: #fff !important;
}
// .multiple-select .ng-select .ng-arrow-wrapper .ng-arrow {
//   border-bottom: 0px !important;
// }
 .multiple-select .ng-select .ng-arrow-wrapper {
	top: 0px !important;
	bottom: auto !important;
	height: 28px;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-arrow-wrapper .ng-arrow {
	margin-bottom: 2px;
}
.multiple-select .ng-dropdown-panel.ng-select-bottom {
	box-shadow: none;
}
.multiple-select .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
	top: 8px;
}
.multiple-select .ng-select .ng-has-value .ng-placeholder,
.multiple-select .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
	transform: translateY(-1.48125em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.multiple-select {
	display: inline-block;
	width: 100%;
}
.multiple-select .form-group {
	margin-right: 15px;
	float: left;
	width: calc(33.33% - 10px);
}
.multiple-select .form-group:last-child {
	margin-right: 0px;
}
.multiple-select .ng-select.ng-select-single .ng-select-container .ng-value-container {
	border: 0px !important;
	padding-left: 10px;
}
.multiple-select .ng-optgroup .ng-option-label {
	font-size: 15px;
	margin: 0 0 7px 0;
	font-weight: bold;
}
.multiple-select .ng-optgroup.ng-option-disabled {
	height: auto !important;
	line-height: 32px !important;
	color: #fff !important;
	background-color: #44423F;
}
.multiple-select ng-select {
    padding-bottom: 0px !important;
}
/*****  start Checkbox *****/

.checkbox-box {
	width: 20px;
	height: 20px;
	display: block;
	position: relative;
	cursor: pointer;
	margin-bottom: 0px;
}
.checkbox-box span {
	font-size: 0px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: 20px;
	text-align: left;
	font-family: 'Poppins', sans-serif;
	display: inline-block;
}
.checkbox-box span:after {
	top: 0px;
	content: "";
	width: 20px;
	height: 20px;
	border: 1px solid #c1c1c1;
	background-color: $white-color;
	position: absolute;
	left: 0px;
}
.checkbox-box input {
	position: absolute;
	width: 20px !important;
	left: 0px;
	opacity: 0;
	z-index: +9999;
	height: 20px;
	cursor: pointer;
	margin: 0px !important;
}
.checkbox-box .checkbox-box input[type=checkbox]:checked+span:after {
	border-color: $primary-color;
}
.checkbox-box span:before {
	top: 0px;
	content:"\eb75";
	position: absolute;
	width: 20px;
	height: 20px;
	left: 0px;
	z-index: 9;
	margin: 0;
	opacity: 0;
	text-align: center;
	line-height: 25px;
	font: normal normal normal 24px/1 "remixicon";
	font-size: 16px;
	line-height: 20px;
	color: #fff;
    background: #28a745;
}
.checkbox-box input[type=checkbox]:checked+span:before {
	opacity: 1;
}
.checkbox-false span:before {
	content: "\F1398";
	color: #ff3d60 !important;
}
.action-checkbox {
	display: flex;
}
/***** End Checkbox *****/
/*****  start Radio box *****/

.radio_label .radio-box {
	// width: 20px;
	position: relative;
	cursor: pointer;
	margin-bottom: 0px;
	// height: 20px;
	display: inline-block;
}
.radio_label .radio-box span {
	display: inline-block;
	padding-left: 30px;
	font-size: .9rem;
}
.radio_label .radio-box span:after {
	content: "";
	width: 20px;
	height: 20px;
	display: inline-block;
	position: absolute;
	left: 0px;
	top: 0px;
	background: #ffff;
	border-radius: 60px;
	border: 1px solid #c1c1c1;
}
.radio_label .radio-box span:before {
	content: "";
	width: 12px;
	height: 12px;
	display: inline-block;
	position: absolute;
	left: 4px;
	top: 4px;
	background: #cecece;
	border-radius: 60px;
	z-index: 9;
	opacity: 0;
}
.radio_label .radio-box input {
	position: absolute;
	width: 20px;
	left: 0px;
	opacity: 0;
	z-index: +9999;
	height: 20px;
	cursor: pointer;
}
.radio_label .radio-box input[type=radio]:checked+span:before {
	opacity: 1;
	background-color: $primary-color;
}
.radio_label .radio-box input[type=radio]:checked+span:after {
	border-color: $primary-color;
}
.radio_label .radio-box {
	margin-right: 15px;
	margin-bottom: 10px;
}
.radio_label .radio-box:last-child {
	margin-right: 0px;
}
/***** End Radio kbox *****/
// date_picker 21-07-2021
.date_picker {
	margin-bottom: 8px;
  }
  
  .date_picker .form-control,.date_picker .input-group {
	width: auto !important;
	display: inline-block;
  }
  .date_picker .calendar-icon {
	display: flex;
	align-items: center;
	font-size: 13px;
	// justify-content: center;
	// min-width: 120px; 
	cursor: pointer;
  }
  
  .date_picker .calendar-icon i {
	margin-left: 10px;
  }
  
  .date_picker .form-control {padding: 4px 12px;height: auto !important;}
  .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
	line-height: 14px !important;
  }
  .date_picker .form-control {
	font-size: 13px;
  }
  
/******* Start custom css ********/
.badge {
    line-height:20px;
    font-size: 90%;
    padding:0px 7px;
 }
.table .thead-light th {
	background: #354d9b;
	color: #fff;
	border: 0px;
	font-size: 13px !important;
}
.badge-dark{background-color:#354d9b ;}
.table {
	border-radius: 6px !important;
	overflow: hidden;
}
thead.thead-light {
	border-radius: 9px;
	overflow: hidden;
}

// .angular-editor-toolbar-set:nth-child(2),
// .angular-editor-toolbar-set:nth-child(7),
// .angular-editor-toolbar-set:nth-child(8),
// .angular-editor-toolbar-set:nth-child(9),
// .angular-editor-toolbar-set:nth-child(10),
// .angular-editor-toolbar-set:nth-child(11),
// ae-select.select-heading {
//     display: none !important;
// }

.angular-editor-toolbar-set:last-child {
    margin-right: 0px !important;
}
.angular-editor-textarea {
  height: 250px !important; 
   outline: none;
  margin-top: 0px !important;
    // border-top: 0px !important;
}
ae-select.select-heading button.ae-picker-label {
    line-height: 19px;
}
/** width **/
.angular-editor-textarea::-webkit-scrollbar {
	width:3px;
	background: #f5f5f5;
  }
  
  /** Track **/
  .angular-editor-textarea::-webkit-scrollbar-track {
	border-radius: 0px;
  }
  
  /** Handle **/
  .angular-editor-textarea::-webkit-scrollbar-thumb {
	background: #da1773;
	border-radius: 0px;
  }
 
.subscription_options .multiple-select .ng-select .ng-select-container {
    min-height:32px !important;
}

.subscription_options .multiple-select .ng-select .ng-arrow-wrapper {
    height: 26px;
}


.chat-conversation .conversation-list .ctext-wrap-content {
    padding: 8px 10px;
    background-color: #354d9b;
    font-size: 12px;
}
.chat-conversation .conversation-list {
    margin-bottom: 10px;
}
 .scroll-style .modal-body::-webkit-scrollbar {
        width: 4px;
    }

   .scroll-style .modal-body::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

   .scroll-style .modal-body::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0;
        border-radius: 0;
        background: #da1773;
    }
	.daterangepicker td.in-range {
		background-color: #fff7f3;
	}
	.daterangepicker td.active, .daterangepicker td.active:hover,.daterangepicker .ranges li.active {
		background-color: #da1773;
	}


// Google form


.modal_header_title {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	line-height: 14px !important;
	position: relative;
}
.modal_header_title .switch_profile {
	right: 16px;
}
.modal_header_title h5 {
	line-height: 24px !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul {
	background: #f5f5f5;
}
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > ul * {
	color: #000 !important;
}
.multiple-select .ng-dropdown-panel.ng-select-bottom {
	top: 100% !important;
}
.multiple-select .ng-dropdown-panel.ng-select-top {
	bottom: calc(100% - -1px);
}
.box-input ul li {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}
.box-input ul li .input {
	width: calc(100% - 24px);
	display: inline-block;
	border: 0px;
	outline: none;
	background: none;
}
.box-input ul li .icon-box {
	display: inline-block;
}
.box-input {
	display: inline-block;
	width: 100%;
}
.box-input ul li:last-child {
	margin-bottom: 0px;
}
.box-input ul li.add-option .input {
	width: 80px;
	margin-right: 5px;
}
.box-input .OtherDivider {
	margin-right: 5px;
}
.box-input ul li.add-option .other-exportLabel {
	color:  $primary-color;
	cursor: pointer;
}
.box-input ul li .input:focus {
	border-bottom: 1px solid  $primary-color;
}
.question_row {
	background: #fff;
	padding: 20px 30px;
}
.question_row .item-dlg-dragHandle {
	cursor: move;
	display: flex;
	justify-content: center;
	opacity: 0.3;
	height: 24px;
	overflow: hidden;
	width: 24px;
	position: relative;
	margin: auto;
}
.question_row .item-dlg-dragHandle:after {
	content: "";
	display: inline-block;
	background: url(/assets/images/qp_sprite139.svg);
	height: 24px;
	background-position: 0 -3479px;
	width: 24px;
	background-size: cover;
}
.ItemTitleRow {
	box-align: start;
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 18px;
}
.ItemTitleRow .ItemTitleInput {
	box-flex: 1;
	flex-grow: 1;
	margin-right: 8px;
	margin-bottom: 8px;
}
.upload_file input {
	margin: 0px !important;
	opacity: 0;
	border: 0px !important;
	width: 48px;
	height: 48px;
	z-index: 999;
	position: absolute;
}
.upload_file {
	width: 35px;
	position: relative;
	display: inline-block;
	margin-right: 8px;
	border-radius: 60px;
}
.input-action .upload_file.upload_video:after {
	background-position: 10px -5018px !important;
}
.ItemTitleRow .upload_video:after {
	background-position: 10px -5015px !important;
}
.upload_file:hover {
	background-color: rgba(95, 99, 104, 0.039);
}
.upload_file:after {
	cursor: pointer !important;
	position: absolute;
	left: 0px;
	width: 33px;
	height: 33px;
	/* background: url(qp_sprite139.svg); */
	/* background-position: 12px -7016px; */
	/* background-size: auto; */
	/* background-repeat: no-repeat; */
	top: 8px;
	content: "";
	font-family: remixicon !important;
	font-style: normal;
	text-align: center;
	font-size: 20px;
}
.ItemTitleRow .ItemTitleInput textarea {
	min-height:50px;
	border-radius: 0px;
	border: 0px;
	background: #ececec;
	border-bottom: 1px solid #80868b;
	resize: none;
	font-family: "Google Sans", Roboto, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 18px;
}
.ng_select_option .ng-select-container .ng-value-container .ng-input > input {
	display: none;
}
.ng_select_option .ng-select .ng-select-container:after {
	display: none;
}
.ng_select_option {
	width: 220px;
}
.ng_select_option .ng-select-container {
	border: 1px solid #dadce0;
}
.ng_select_option .ng-dropdown-panel.ng-select-bottom {
	z-index: 99999 !important;
}
.ng_select_option .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items {
	height: 100% !important;
	max-height: 100vh !important;
}
.ng_select_option .ng-select .ng-clear-wrapper {
	display: none !important;
}
.ng_select_option .ng-select .ng-select-container .ng-value-container {
	margin-top: 0px !important;
	border: 0px !important;
}
.ng_select_option .ng-select .ng-select-container .ng-value-label {
	padding-left: 48px;
	line-height: 22px;
	display: block !important;
	margin-top: 8px !important;
}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	padding-left: 48px;
}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	padding-left: 0px;
}
.ng_select_option .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label,
.ng_select_option .ng-value-label {
	background-size: auto;
	background-repeat: no-repeat;
	background: url(/assets/images/qp_sprite139.svg);
	background-position: 10px -7020px;
	padding-left: 48px;
	background-repeat: no-repeat;
	background-size: auto;
	line-height: 24px;
	display: block;
	margin-top: 10px;
}
.ng_select_option .ng-select.ng-select-single .ng-select-container .ng-arrow-wrapper {
	bottom: 12px;
}
.ng_select_option .ng-option-label[title="Short answer"],
.ng_select_option .ng-value-label[title="Short answer"] {
	background-position: 10px -6162px !important;
}
 
.ng_select_option .ng-option-label[title="Multiple choice"],
.ng_select_option .ng-value-label[title="Multiple choice"] {
	background-position: 10px -1118px !important;
}
.ng_select_option .ng-option-label[title="Single choice"], 
.ng_select_option .ng-value-label[title="Single choice"],
.ng_select_option .ng-option-label[title="Yes/No"], 
.ng_select_option .ng-value-label[title="Yes/No"] {
    background-position: 10px -6318px !important;
}
 
.ng_select_option ng-select {
	padding: 0px !important;
}
.ViewMediaImagePos {
	margin: 0 24px 24px 42px;
	position: relative;
}
.remove-exportLabel {
	cursor: pointer;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
	background-color: #f1f3f4;
	height: 48px;
	border-radius: 50%;
	width: 48px;
	float: left;
	position: absolute;
	left: -12px;
	top: 0px;
	z-index: 99;
}
span.remove-exportLabel:after {
	content: "";
	cursor: pointer !important;
	position: absolute;
	left: 0px;
	width: 48px;
	height: 33px;
	background: url(/assets/images/qp_sprite139.svg);
	background-position: 12px -3245px;
	background-size: auto;
	background-repeat: no-repeat;
	top: 8px;
}
.box-input ul li {
	display: inline-block;
	width: 100%;
	margin-bottom: 10px;
}
.box-input ul li .input {
	width: calc(100% - 25px);
	display: inline-block;
	border: 0px;
	border-bottom: 1px solid #f5f5f5;
	outline: none;
}
.box-input ul li .icon-box {
	display: inline-block;
}
.box-input {
	display: inline-block;
	width: 100%;
}
.box-input ul li:last-child {
	margin-bottom: 0px;
}
.box-input ul li.add-option .input {
	width: 80px;
	margin-right: 5px;
}
.box-input .OtherDivider {
	margin-right: 5px;
}
.box-input ul li.add-option .other-exportLabel {
	color:  $primary-color;
	cursor: pointer;
}
.box-input ul li .input:focus {
	border-bottom: 1px solid  $primary-color;
}
.box-input ul li .ViewMediaImagePos {
	margin: 10px 0px 0px 0px;
	max-width: 140px;
	display: inline-block;
}
.box-input ul li .ViewMediaImagePos .remove-exportLabel {
	height: 40px;
	width: 40px;
	left: auto;
	right: -15px;
	top: -4px;
	z-index: 9;
}
.box-input ul li .ViewMediaImagePos .remove-exportLabel:after {
	width: 30px;
	height: 20px;
	background-size: 22px;
	background-position: 9px -2748px;
}
.box-input ul li {
	padding-left: 32px;
	position: relative;
}
.box-input ul li .input-row {
	display: flex;
	// flex-wrap: wrap;
}
// .input-row  .multiple-select-sm {
//     margin-left: 20px;
//     width: 230px;    display: none;
// }
// .input-row.add  .multiple-select-sm { display:block;}
.box-input ul li .checkbox-box {
	position: absolute;
	left: 0px;
}
.input-row .d-flex {
    width: 100%;
}


.multiple-choice li:before {
	width: 20px;
	height: 20px;
	display: inline-block;
	position: absolute;
	left: 0px;
	top: 0px;
	content: "";
	background: #ffff;
	border-radius: 60px;
	border: 1px solid #c1c1c1;
}
.checkboxes-choice li:before {
	top: 0px;
	content: "";
	width: 20px;
	height: 20px;
	border: 1px solid #c1c1c1;
	background-color: #ffffff;
	position: absolute;
	left: 0px;
}
.multiple-choice .radio-box {
	width: 20px;
	position: relative;
	cursor: pointer;
	margin-bottom: 0px;
	height: 20px;
	display: inline-block;
}
.multiple-choice .radio-box span {
	font-size: 0px;
}
.multiple-choice .radio-box span:after {
	content: "";
	width: 20px;
	height: 20px;
	display: inline-block;
	position: absolute;
	left: 0px;
	top: 0px;
	background: #ffff;
	border-radius: 60px;
	border: 1px solid #616161;
}
.multiple-choice .radio-box span:before {
	content: "";
	width: 12px;
	height: 12px;
	display: inline-block;
	position: absolute;
	left: 4px;
	top: 4px;
	background: #616161;
	border-radius: 60px;
	z-index: 9;
	opacity: 0;
}
.multiple-choice .radio-box input {
	position: absolute;
	width: 20px;
	left: 0px;
	opacity: 0;
	z-index: 9999;
	height: 20px;
	cursor: pointer;
}
.multiple-choice .radio-box input[type=radio]:checked + span:before {
	opacity: 1;
}
.multiple-choice ul li .radio-box {
	position: absolute;
	left: 0px;
}
.box-input ul {
	margin: 0px;
	padding: 0px;
}
.input-action {
	display: flex;
}
.input-action .upload_file:after {
	top: 0px;
	height: 24px;
	background-position: 12px -7020px;
	width: 46px;
}
.input-action .upload_file:hover {
	background: none;
}
.input-action .upload_file input {
	height: 24px;
}
.input-action .iconbuttonContentSpan {
	cursor: pointer;
}
.input-action .upload_file {
	opacity: 0 !important;
}
.box-input ul li:hover .upload_file {
	opacity: 1 !important;
}
.question_row .ng-dropdown-panel.ng-select-bottom {
	top: 101%;
}
.integer-choice ul li {
	padding-left: 0px;
	max-width: 120px;
	margin-right: 15px;
}
.integer-choice ul li .input {
	width: 100%;
}
.short-answer ul li,
.gps-choice ul li {
	padding-left: 0px;
}
.question_row .ng-dropdown-panel .ng-dropdown-panel-items {
	height: 100%;
	max-height: 100vh;
}
.question_row .ng-dropdown-panel.ng-select-top {
	bottom: 101%;
}
.image-choice ul li .input-action .upload_file,
.video-choice ul li .input-action .upload_file {
	opacity: 1 !important;
}
.image-choice ul li .ViewMediaImagePos,
.video-choice ul li .ViewMediaImagePos {
	width: 100%;
	max-width: 100%;
	margin-top: 30px;
}
.video-choice ul li .video-upload {
	width: 100%;
}
.video-choice .upload_file:after {
	background-position: 10px -5018px !important;
}
.video-choice ul li,
.image-choice ul li {
	padding-left: 0px;
}
.popup_assessment .modal-dialog {
	max-width: 730px;
}
.question_row button.btn-warning {
	margin-top: 30px;
}
// .input-row.add .d-flex {
//     width: calc(100% - 250px);
// }

 

 
 
 .section-assessment .page-title-right2 {
	 justify-content: flex-end;
   }
   
   .section-assessment .modal_header_title.modal-header {
	 border: 0px;
	 padding: 0px !important;
	 margin-bottom: 8px;
   }
   
   .section-assessment .switch_profile {
	 position: initial;
   }
   
   .section-assessment .school-bg {
	 width: 100px;
	 height: 100px;
	 padding: 0px;
   }
   
   .media-capture-row td {
	 vertical-align: top !important;
   }
   
   .media-capture-row td span {
	 font-size: 14px;
	 margin-left: 10px;
	 vertical-align: top !important;
	 font-weight: bold !important;
	 display: none;
   }
   
   .audio-box {
	 max-width: 250px;
   }
   
   .audio-box audio {
	 outline: none;
	 width: 100%;
	 background: #f3f3f3;
	 height: 40px;
   }
   
   .video_box video {
	 width: 100%;
	 outline: none;
   }
   
   .signature-bg {
	 border: 1px solid #eff2f7b3;
	 background-size: contain !important;
	 background-position: center;
   }
   
   .modal-header {
	 position: relative;
   }
   
   .modal-header .switch_profile {
	 top: 20px;
	 right: 50px;
   }
   
   .modal-header .switch_profile .label-text {
	 font-weight: 500 !important;
   }
   
	
   
   .section-question .question_header .school-bg {
	 width: 60px;
	 height: 60px;
	 padding: 0px;
	 display: block;
   }
   
   .image-text-box tr td .school-bg {
	 width: 55px !important;
	 height: 55px !important;
	 margin-left: 10px;
	 
	 display: block;
	 margin-left: 0px;
   }
   
   .image-text-box tr td {
	 vertical-align: top !important;
   }
   .section-question .question-row:before {
	 transform: rotate(90deg);
	 content: "";
	 width: 20px;
	 height: 15px;
	 background: url(/assets/images/qp_sprite139.svg);
	 background-position: -2px -3772px;
   }
   .section-question .q-title {
	 padding-left: 0;
   }
   
   .section-question.mt-3 {
	 margin-top: 0px !important;
   }
   
   
   
   .question-row span.action_btn {
	 display: flex;
	 align-items: flex-start;
   }
   
   .section-question .q-title {
	display: flex;
    align-items: flex-start;
	 width: calc(100% - 210px);
   }
   
   @media (max-width: 479px) {
   
	 .section-question .q-title {
   
	   width: 100%;
	 }
   
	 .question-row span.action_btn {
	   display: flex;
	   // width: 100%;
	   align-items: flex-start;
	   padding-top: 10px !IMPORTANT;
	 }
   }
   
   .question_header {
	   display: flex;
	   width: 100%;
	   margin-bottom: 10px;
	   justify-content: space-between;
   }
   .section-question.mt-3 {
	 margin-top: 0px !important;
   }
   .popup_assessment .modal-title {display: flex;align-items: baseline;justify-content: space-between;width: 100%;}
 
   .based-answer {
	   padding: 7px 15px;
	   line-height: 15px;
	   border: 1px solid #F6B017;
	   background: #f7a714;
	   color: #202b84;
	   opacity: 1    !important;
		font-family: Inter,sans-serif;
	 font-weight: 600;
	 font-size: 13px;
   }
 
 
   // 
 .add-Condition { margin-top:20px;padding-top:20px; border-top: 1px solid #202b84;}
 .add-Condition .card-title {color: #f7a714;
   display: flex;
   font-size: 14px;
   flex-wrap: wrap;}
   .add-Condition .based-answer {
	 padding: 6px 8px;
	 margin-left: 16px;
 }
 .add-Condition ul {padding: 0px;list-style: none;margin: 0px;}
 .add-Condition ul li {margin-bottom: 10px;}
 .add-Condition  .checkbox-box {width: auto;}
 .add-Condition .checkbox-box span {font-size: 13px;padding-left: 30px;color: #000000;font-family: Nunito,sans-serif;}
 .add-Condition .multiple-select-sm {margin-bottom: 15px;}
 .add-condition[aria-expanded="true"],add-condition {
   display: none;
 }

// End Google form

// program
.program-list .nav-pills .nav-link {
    border-radius: 0px;
    background: none;
    margin-bottom: 6px;
    padding: 0px 0px 6px 0px;
    border-bottom: 1px solid #f5f5f5;
}

.program-list .nav-pills .nav-link.active {
    color: #da1773;
}

.program-list .nav-pills .nav-link:last-child {
    border: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.program-editor .angular-editor-textarea {
    height: 180px !important;
}
button.angular-editor-button {
    padding: 3px 4px !important;
    min-width: 25px !important;
    font-size: 12px;}
.program-editor .angular-editor-toolbar-set:nth-child(2) {
    display: inline-block !IMPORTANT;
}
.program-editor .angular-editor-toolbar-set:nth-child(10) {
    display: inline-block !important;
}

.program-editor button#insertHorizontalRule-editor1,
.program-editor button#unlink-editor1,.program-editor button#link-editor1 {
    display: none !important;
}
button#strikeThrough-editor1,
button#subscript-editor1,
button#superscript-editor1{ display:none!IMPORTANT;}

.description p {
    margin-bottom: 0px;
}

.description:first-child p {
    margin-bottom: 6px;
}
.badge-dark{cursor: pointer;}
// cdk-drag
.cdk-drag {cursor: move;}


// data Tags

.data-value {
	background-color: #354d9b;
	margin: 0 3px 3px 0;
	display: inline-block;
	border-radius: 3px;
  }
  
  .data-value-label {
	padding: 1px 5px;
	display: inline-block;
	color: #fff;
	font-size: 11px;
	font-weight: 900;
  }
  
  .data-value-icon {
	border-left: 1px solid #ffffff;
	background: no-repeat;
	color: #fff;
	display: inline-block;
	padding: 1px 5px;
	line-height: 17px;
	cursor: pointer;
	font-weight: 900;
  }
  
  .data {
	display: inline-block;
	width: 100%;
	margin-bottom: 5px;
  }
  .show-mess {
    float: right;
    background: #354d9b;
    width: 20px;
    border-radius: 60px;
    text-align: center;
    font-size: 75%;
    height: 20px;
    line-height: 20px;
}
.show-mess {
    background: #da1773;
}

 
.metismenu li.active .show-mess {
    background: #354d9b;
}
/**
* Responsive
**/

@media (max-width: 769px) {
	.table-responsive .table thead {
		display: none;
	}
	.table-responsive .table tbody td {
		display: block;
		text-align: end;
		position: relative;
		padding-left: 0px;
		padding-right: 0px;
	}
	.table-responsive .table tbody td .user-icon {
		float: none;
	}
	.table-responsive .table tbody td:before {
		content: attr(data-title) ":";
		position: absolute;
		left: 5px;
		color: #505d69;
		font-weight: 600;
		font-family: Nunito, sans-serif;
	}
	.table-responsive .table tbody td[data-title="Labels"] {
		height: 40px;
	}
	.table-responsive .table tbody td:first-child {
		border-top: 0px;
	}
	.description-td .description-text {
		margin-left: auto;
	}
	.table-responsive .table tbody td:last-child {
		border-bottom: 1px solid #c9cbce;
	}
	.table-responsive .table tbody tr:last-child td:last-child {
		border-bottom: 0px;
	}
	.dataTables_length label,
	.dataTables_filter,
	.table-responsive .dataTables_info {
		text-align: center;
		width: 100%;
	}
	.table-responsive>.row>div[class^="col-"] {
		padding: 0px;
	}
	.filter_boxs {
		flex-wrap: wrap;
	}
	.dataTables_filter_select {
		margin-right: 0px;
		width: 100%;
		text-align: center;
	}
	.table-style td[data-title="Picture"],
	.table-style td[data-title="Name"] {
		width: 100%;
	}
	.table-responsive .table tbody td {
		// padding-left: 85px !important;
		padding-left: 110px !important;
		min-height: 43px;
	}
	/**** Start custom css *****/
	.bg_img {
		margin-left: auto;
	}
}


@media (max-width: 479px) {
	.filter_s {
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
	}
	.filter_s .select_box_text {
		width: 100%;
		text-align: center;
		display: block;
		margin-bottom: 10px;
	}
	.filter_s .select_box select {
		font-size: 10px;
	}
	.filter_s .ml-3 {
		margin-left: 0px !important;
	}
	/**** Start custom css *****/
	.daterangepicker .drp-buttons {
		text-align: left !important;
	}
	
	.daterangepicker .drp-selected{
		padding-right: 0px !important;
	}
	.dashboard-topbar .page-title-box.justify-content-between {
		flex-wrap: wrap;
	}
	
	.dashboard-topbar .page-title-right2 {
		padding-top: 10px;
		width: 100%;
		justify-content: right;
	}
	.chat-blank .card-body {min-height: 350px !important;}
}
@media (max-width: 369px) {
	.table-responsive .table tbody td {
		font-size: 13px;
	}
	.filter_s .select_box select {
		margin-bottom: 10px;
		line-height: 18px;
	}
	.filter_s .select_box+.select_box {
		float: left;
		width: calc(50% - 5px);
	}
	.filter_s .select_box:last-child {
		margin-left: 5px;
	}
	/**** Start custom css *****/
}



// 
.page_policy *,
.page_policy *::before,
.page_policy *::after {box-sizing: border-box;}
.page_policy b, .page_policy strong {font-weight: 600;}
.page_policy {margin: 0px;font-family: 'Poppins', sans-serif;}
.page_policy .header {border-bottom: 1px solid #0000000f;background: #fcfcfc;}
.page_policy .header .logo { display: block;padding: 10px 0;}
.page_policy .header .logo img{ display: block;height:40px;}
.page_policy .heading-title {font-size: 28px;margin-bottom: 15px;}
.page_policy .content-page {padding: 30px 0; height: 100%; min-height: calc(100vh - 110px);}
.page_policy .content-page a{color:#793DC7}
.page_policy .content-page p,.page_policy .content-page li {font-size: 90%;}
.page_policy .content-page h3 {font-size: 20px;}
.page_policy .content-page h3 strong {font-weight: 600;}
.page_policy .content-page li {margin-bottom: 5px;}
.page_policy .content-page li:last-child {margin-bottom: 0px;}
.page_policy .content-page ul {padding-left: 24px;}
.page_policy footer.copyright {background: #fcfcfc;text-align: center;padding: 14px 0;font-size: 80%;border-top: 1px solid #0000000f;}
