/* You can add global styles to this file, and also import other style files */
.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.table-responsive {
  overflow: hidden;
}

.limitTextHeight {
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: inline-block;
}

.description {
  position: relative;
}

.description-text {
  // white-space: initial;
  font-size: 12px;
  max-width: 300px;
}

//   .description .limitTextHeight {
//     width: auto;
//     white-space: normal;
//     height: 40px;
//     max-width: 100%;
// }
.ngx-spinner-overlay {
  z-index: 999999 !important;
}

// .custom-richtexteditor {
//     height: 200px !important;
//     overflow-y: scroll !important;
//     width: 100%;
// }
.e-richtexteditor .e-rte-content .e-content {
  overflow: auto;
  height: 200px;
}

.e-richtexteditor .e-rte-content .e-content {
  overflow: auto;
  height: 200px;
}

/* width */
.e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar {
  width: 5px;
  background: #79909b;
}

/* Track */
.e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
.e-richtexteditor .e-rte-content .e-content::-webkit-scrollbar-thumb {
  background: #da1773;
  border-radius: 0px;
}

.error-msg.mb-1.ng-star-inserted {
  color: rgb(255 0 0);
}

.description img {
  width: 70px;
}

.angular-editor-textarea img {
  width: 100%;
}

.q-title span {
  word-break: break-all;
}